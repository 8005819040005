
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #000;
    border-bottom: 1px solid #FF8E4D; /* Add a border-bottom */

  }
  
  .logo {
    width: 200px;
  }
  
  .menu {
    display: flex;
    gap: 20px;
  }
  
  .menuItem {
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 12px;
    color: #fff;
  }
  
  .loginButton {
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    padding: 8px 16px;
  }
  
  .menuItem:hover {
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .menu {
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .menuItem {
      font-size: 0.9rem;
    }
  
    .loginButton {
      font-size: 0.9rem;
    }
    .logo{
      width: 100px;
    }
    
  }
  @media (max-width: 400px) {
    .navbar {
      flex-direction: row;
      padding: 8px;
    }
  
    .menu {
      flex-direction: row;
      gap: 5px;
    }
  
    .menuItem {
      padding: 4px 6px;
      font-size: 0.75rem;
    }
  
    .logo {
      width: 60px;
    }
  
    .loginButton {
      padding: 4px 8px;
      font-size: 0.75rem;
    }
  }
  