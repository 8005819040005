.BannerContainer {
    position: relative;
    width: 100%;
    height: 400px;
    background: white;
    overflow: hidden;
  }
  
  .ContentSection {
    position: relative;
    height: 100%;
    max-width: 1200px;
    margin: 0 20px;
    padding: 20px;
    z-index: 2;
    margin-top: -20px;
    mix-blend-mode: multiply;
  }
  
  .ProductSection {
    display: flex;
    align-items: center; /* Center-aligned content */
    gap: 40px;
    height: 100%;
  }
  
  .ProductImage {
    height: 310px;
    width: 300px;
    object-fit: contain;
    z-index: 3;
  }
  
  .ProductInfo {
    max-width: 750px;
    margin-top: -30px;
  }
  
  .ProductTitle {
    font-size: 48px;
    font-weight: bold;
    margin: 0 0 10px;
    color: #000 !important;
  }
  .subtitle{
    color: #C21D41;
    font-size: 40px;
    margin: 0;
  }
  
  .ProductDescription {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;
    color: #000 !important;
  }
  
  .ShopNowButton {
    background-color: #ff9966;
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .ShopNowButton:hover {
    background-color: #ff8040;
  }
  
  .WaterBackground {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .LimitedWidth {
    max-width: 600px; /* Apply this if the product ID is not 'amritjal-spray' */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .BannerContainer {
      height: 120px; /* Reduce height for smaller screens */
    }
  
    .ProductSection {
      flex-direction: row; /* Maintain horizontal alignment */
      gap: 5px; /* Reduce spacing */
    }
  
    .ProductImage {
      height: 100px; /* Smaller image size */
      width: 90px;
    }
  
    .ProductTitle {
      font-size: 14px; /* Adjust title font size */
      margin: 4px 0;
    }
  
    .ProductDescription {
      font-size: 5px;
      margin: 4px 0;
      max-width: 130px;
    }
  
    .ShopNowButton {
      padding: 5px 15px; /* Adjust button size */
      font-size: 4px; /* Adjust button font size */
      margin-bottom: 8px;
    }
    .ContentSection {
        /* top: -18px; */
        height: 130px;
        margin:0 0;
        margin-top: -20px;
        padding: 20px 0px;
    }
    .ProductInfo{
        margin-bottom: 5px;
        margin-top: 0;
    }
    .WaterBackground{
        height: 120px;
    }
    .subtitle{
        font-size: 10px;
    }
    
  }  