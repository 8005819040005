.exclusionsSection {
    margin: 2rem auto;
    padding: 1rem;
    max-width: 1100px;
  }
  
  .exclusionsTitle {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #000;
    position: relative;
    display: inline-block;
  }
  
  .exclusionsTitle::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff0000;
  }
  
  .exclusionsList {
    /* list-style: none; */
    padding-left: 1rem;
  }
  
  .exclusionsList li {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    .exclusionsList li {
        font-size: 0.7rem;
    }
    .exclusionsTitle{
        font-size: 1.8rem;
    }
    .exclusionsSection{
      padding: 0 2.2rem;
    }
  }