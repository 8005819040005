/* Hero container */
.heroContainer {
    width: 100%;
    height: 100vh;
    background-image: url('../../../../assets/holiprasad/vrindavan_hero.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

/* Overlay */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
}

/* Content wrapper */
.heroContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

/* Text styling */
.heroText {
    margin-left: 10%;
    margin-bottom: -20%;
    position: relative;
    z-index: 2;
}

.heroText h1 {
    font-size: 64px;
    font-weight: 500;
    color: white !important;
    margin: 0;
    line-height: 1.1;
}

.heroText h2 {
    font-size: 64px;
    font-weight: 500;
    color: white;
    margin: 0;
    margin-bottom: 30px;
    line-height: 1.1;
}

/* Button styling */
.bookNowBtn {
    background-color: #ff7f50;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 18px 70px;
    font-size: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.bookNowBtn:hover {
    background-color: #ff6b3d;
}

/* Responsive styles */
@media (max-width: 768px) {
  .heroContainer{
    height: 35vh;
  }
    .heroText {
        margin-left: 5%;
        margin-bottom: -20%;
    }
    
    .heroText h1,
    .heroText h2 {
        font-size: 20px;
        margin-bottom: 0.5rem;
    }
    .bookNowBtn{
      padding: 5px 18px;
      font-size: 10px;
    }
}

@media (max-width: 480px) {
    .heroText h1,
    .heroText h2 {
        font-size: 28px;
    }
    
    .bookNowBtn {
      padding: 5px 18px;
      font-size: 10px;
    }
}

  