.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* min-height: 100vh; */
}

.leftSection {
  background: linear-gradient(135deg, #D65A20, #E27F25);
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.logo {
  color: white !important;
  font-size: 8.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  margin-top: 0;
}

.welcomeText {
  color: white;
  font-size: 1.89rem;
  line-height: 1.5;
  /* max-width: 500px; */
  text-align: right;
}

.highlight {
  font-weight: 600;
}

.rightSection {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.loginForm {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
}

.connectHeading {
  color: white;
  font-size: 3rem;
  margin-bottom: 2rem;
  text-align: center;
}

.inputGroup {
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 1rem;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.input:focus {
  outline: none;
  border-color: #f15a22;
}
.buttondiv{
  display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
}
.loginButton {
  width: 50%;
  padding: 0.75rem;
  background-color: #D96521;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.loginButton:hover {
  background-color: #d94d1b;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1.5fr 1fr;
  }

  .leftSection {
    padding: 3rem 2rem;
  }

  .logo {
    font-size: 4rem;
  }

  .welcomeText {
    font-size: 1rem;
    text-align: center;
  }

  .connectHeading {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }

  .leftSection {
    padding: 2rem;
    text-align: center;
  }

  .logo {
    font-size: 2.5rem;
    text-align: center;
  }

  .welcomeText {
    font-size: 1rem;
    margin: 0 auto;
    text-align: center;
  }

  .loginForm {
    padding: 1.5rem;
  }

  .connectHeading {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .leftSection {
    padding: 1rem;
  }

  .logo {
    font-size: 3.8rem;
  }

  .welcomeText {
    font-size: 0.9rem;
    text-align: justify;
  }

  .connectHeading {
    font-size: 2rem;
  }
  .container{
    min-height: auto;
  }
  .input {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .loginButton {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}
