.resultContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

.statusIcon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.success .statusIcon {
  background-color: #e7f7ed;
}

.failure .statusIcon {
  background-color: #ffebee;
}

.innerCircle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success .innerCircle {
  background-color: #28a745;
}

.failure .innerCircle {
  background-color: #dc3545;
}

.statusTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.orderNumber {
  color: #6c757d;
  margin-bottom: 8px;
  text-align: center;
}

.thankYou {
  color: #6c757d;
  margin-bottom: 24px;
  text-align: center;
}

.trackingContainer {
  width: 100%;
  max-width: 600px;
  margin: 32px 0;
}

.trackingSteps {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
}

.trackingLine {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #e9ecef;
  z-index: 1;
}

.step {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 0 10px;
}

.stepIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.stepActive .stepIcon {
  background-color: #FF8E4C;
  color: white;
}

.stepPending .stepIcon {
  background-color: #e9ecef;
  color: #adb5bd;
}

.stepDate {
  font-size: 14px;
  color: #6c757d;
}

.orderCard {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
  max-width: 500px;
  width: 900px;
    height: 100px;
    padding: 16px;
}

.productInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.productImage {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.productDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.productName {
  font-weight: 500;
}

.orderId {
  font-size: 14px;
  color: #6c757d;
}

.trackLink {
  color: #FF8E4D;
  text-decoration: none;
  font-size: 14px;
}

.priceQty {
  text-align: right;
}

.price {
  font-weight: 500;
}

.quantity {
  font-size: 14px;
  color: #6c757d;
}

.actionButton {
  background-color: #000;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 24px;
  transition: background-color 0.2s;
}

.actionButton:hover {
  background-color: #333;
}

.cartLink {
  color: #ff6b6b;
  text-decoration: none;
  margin-top: 16px;
}

.checkIcon,
.crossIcon {
  font-size: 40px;
  color: white;
}

.checkIconSmall {
  font-size: 20px;
  color: white;
}

.packageIcon {
  font-size: 20px;
  color: white;
}


@media (max-width: 768px) {
  .trackingSteps {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .trackingLine {
    width: 2px;
    height: 100%;
    left: 20px;
    top: 0;
  }

  .step {
    flex-direction: row;
    gap: 16px;
    width: 100%;
    padding-left: 0;
  }

  .stepDate {
    margin-top: 0;
  }

  .orderCard {
    /* flex-direction: column; */
    max-width: 350px;
    gap: 16px;
  }

  .priceQty {
    text-align: left;
  }
  .trackingContainer{
 width:auto;
  }
}
