.tripCard {
    background: white;
    border-radius: 25px;
    padding: 2rem;
    max-width: 1100px;
    margin: 2rem auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .tripTitle {
    font-size: 3.8rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #000 !important;
  }
  
  .tripFeatures {
    padding: 0;
    margin: 0;
  }
  
  .tripFeatures li {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }
  
  .tripFeatures strong {
    color: #000;
    font-weight: 600;
  }
  
  .tripFeatures span {
    color: #333;
  }
  
  .tripTagline {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
  }
  
  .tripInnerCard {
    display: flex;
    justify-content: center;
  }
  
  /* Responsive styles for devices less than 768px */
  @media (max-width: 768px) {
    .tripCard {
      padding: 1.5rem;
      max-width: 80%;
    }
  
    .tripTitle {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  
    .tripFeatures li {
      font-size: 0.63rem;
      margin-bottom: 0.5rem;
    }
  
    .tripTagline {
      font-size: 0.7rem;
    }
  }
  
  