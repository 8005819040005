/* body {
  background-color: #000;
} */

.profileCard {
  background: white;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.profileHeader {
  background: white;
  padding: 20px;
  border-bottom: 2px solid #f0f0f0;
}

.profileHeader h1 {
  color: #DB6922;
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.profileContent {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  padding: 20px;
  align-items: center;
}

.profileImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileDetails {
  display: grid;
  gap: 15px;
}

.detailRow {
  display: grid;
  grid-template-columns: 140px 1fr auto;
  align-items: center;
  gap: 10px;
}

.detailLabel {
  color: #DB6922;
  font-weight:700;
  font-size: 1rem;
}

.detailValue {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
}

.editIcon {
  color: #DB6922;
  cursor: pointer;
  transition: transform 0.2s;
}

.editIcon:hover {
  transform: scale(1.1);
}

.shareSection {
  text-align: right;
  padding: 10px;
  border-top: 2px solid #f0f0f0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.shareLink {
  color: #DB6922;
  text-decoration: none;
  font-size: 0.8rem;
}

.shareIcons {
  display: flex;
  gap: 10px;
}

.shareButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #DB6922;
  padding: 5px;
  transition: transform 0.2s;
}

.shareButton:hover {
  transform: scale(1.1);
}

.earningsContainer {
  max-width: 800px;
  margin: 80px auto;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.earningsTable {
  width: 100%;
  border-collapse: collapse;
}

.earningsTable th {
  background: white;
  color: #DB6922;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  padding: 10px 15px;
  border-bottom: 2px solid #f0f0f0;
}

.earningsTable td {
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;
  color: #333;
}

.earningsTable tr:lastChild td {
  border-bottom: none;
}

.earningsTable tr:hover {
  background-color: #fff8f3;
}

.totalEarnings {
  background: #DB6922;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  margin: 20px auto;
  display: inline-block;
}

.updateButton {
  background: #DB6922;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0 auto 20px;
  display: block;
}

.updateButton:hover {
  background: #DB6922;
}

.buttonsContainer {
  text-align: center;
  padding: 10px;
}
/* Dialog Styles */
.formGroup {
  margin-bottom: 1rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

.formGroup input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bankDetailsDialog {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
}

.bankDetailsDialog h3 {
  margin-bottom: 1.5rem;
}

.dialogButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.dialogButtons button {
  flex: 1;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dialogButtons button[type="button"] {
  background: #994f20;
  color: #fff;
}

.dialogButtons button[type="submit"] {
  background: #DB6922;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profileContent {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profileImage {
    margin: 0 auto;
  }

  .detailRow {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }

  .shareSection {
    flex-direction: column;
    text-align: center;
  }

  .earningsTable th,
  .earningsTable td {
    font-size: 0.6rem;
    padding: 8px;
  }

  .totalEarnings,
  .updateButton {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
  .profileDetails {
    margin: 0 auto;
  }
  .earningsContainer, .profileCard{
    width: 90%;
  }
}

@media (max-width: 480px) {
  .profileHeader h1 {
    font-size: 1.8rem;
  }

  .detailLabel,
  .detailValue {
    font-size: 0.9rem;
  }

  .totalEarnings,
  .updateButton {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
  .profileDetails {
    margin: 0 auto;
  }
}
