/* styles.css */

.horizontal-scroll {
    overflow-x: auto;
  }
  
  .table-container {
    text-align: center;
  }
  
  .table-container table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table-container th,
  .table-container td {
    padding: 3px;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 20px;
    text-align: center;
  }
  
  
  @media (max-width: 900px) {
    .table-container th,
    .table-container td {
      font-size: 10px; /* Decrease font size for better readability on smaller screens */
    }
}