.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f8f8; /* Light background for contrast */
}

.card {
  max-width: 600px; /* Limit card width for modern UI aesthetics */
  width: 100%;
  margin: 0 auto;
}

.orderItemContainer {
  margin-bottom: 1.5rem;
}

.orderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.orderImage {
  width: 100px;
  height: 100px;
  border-radius: 25px;
  padding: 5px;
}

.productTitle {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: flex-start;
}

.productTitle .pujaName {
  font-size: 1rem;
  font-weight: 400;
  color: #333;
}

.productTitle .orderNumber {
  font-size: 0.9rem;
  color: #777;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 10px;
}

.rightSection .price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.rightSection .quantity {
  font-size: 0.9rem;
  color: #777;
}

.loader {
  position: fixed;
  top: 45%;
  left: 45%;
}

.profileContainer {
  display: flex;
  background-color: #fff1ea;
  height: 90vh;
}

.mainContent {
  display: flex;
  width: 100%;
}

.panel {
  width: 20%;
  background-color: #ffff;
  padding: 1rem;
  border-right: 1px solid #cccccc;
}

.panelOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.c {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
}

.panel button {
  margin-bottom: 10px;
  padding: 0.5rem 1rem;
  width: 100%;
  border: none;
  display: flex;
  align-items: flex-start;
  background-color: transparent;
  cursor: pointer;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  /* border-bottom: 1px solid; */
}

.footer_contact {
  position: absolute;
  bottom: 0;
  flex-direction: column;
  color: white;
  margin: 1rem 1rem;
}

.contact_info,
.footer_policy_links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer_contact h3 {
  margin-bottom: 10px;
}

.footer_social_links {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social_icon {
  width: 30px;
  height: 30px;
}

.contentArea {
  width: 85%;
  background-color: #fff;
  overflow-y: auto;
}

.profileForm {
  max-width: 800px;
  margin: 0px auto;
  padding: 0 6rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileForm input {
  width: 75%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.profileForm select {
  width: 78%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submitButton,
.logoutButton {
  width: 80%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  color: white;
  font-weight: bold;
}

.submitButton {
  background: linear-gradient(to right, #ffb64d, #ff714e);
}

.logoutButton {
  background-color: #ff2525;
  color: white;
}

.orderCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.status {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.2rem 0.2rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #ff8e4d;
}

.image {
  width: 18%;
  border-radius: 10px;
}

.detailsCard {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.details {
  padding: 0 1rem;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pujaname {
  font-size: 2rem;
  color: #ff714e;
  font-weight: bold;
  order: -1;
}

.price_container {
  gap: 1rem;
  width: 100%;
}

.border {
  border-top: 1px solid #8d8d8d;
}

.pujadescription {
  font-size: 1.2rem;
  color: grey;
}

.price_text {
  font-size: 1.2rem;
  margin-top: 5px;
  font-weight: bold;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 5px;
}
.orderdate {
  font-size: 1em;
  color: black;
  font-weight: bold;
}
/* new profile section */

.profileSection {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  /* align-items: center; */
}
.userDetailSpan {
  font-size: 1rem;
  text-align: left;
  margin: 4px;
}
.userImage {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.userDetails {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.orderButton {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #ffb64d, #ff714e);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  .userDetailSpan {
    font-size: 12px;
    font-weight: bold;
  }
  .profileSection {
    padding: inherit;
  }
  .profileForm {
    max-width: 80%;
  }

  .panel {
    width: 25%;
    padding: 0.5rem;
  }

  .panel button {
    padding: 0rem 0rem;
    font-size: 12px;
  }

  .submitButton,
  .logoutButton {
    font-size: 0.9rem;
  }

  .profileForm h2,
  .profileForm h3 {
    font-size: 1rem;
  }

  .profileForm {
    padding: 1rem;
  }

  .profileForm input {
    width: 75%;
  }

  .profileForm select {
    width: 82%;
  }

  .c {
    font-size: 1.25rem;
  }

  .pujaname,
  .pujadescription,
  .price {
    font-size: 0.7rem;
  }
  .orderdate {
    font-size: 0.7em;
    color: black;
    font-weight: bold;
  }

  .footer_contact {
    visibility: hidden;
  }

  .status {
    font-size: 0.6rem;
  }

  .statusIcon {
    width: 1rem;
    height: 1rem;
  }

  .image {
    width: 50%;
  }

  .profileForm input {
    padding: 8px;
  }
  .orderImage {
    width: 45px;
    height: 51px;
    border-radius: 25px;
  }
  .productTitle {
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-size: 10px;
  }
  .productTitle .pujaName,
  .productTitle .orderNumber,
  .rightSection .price,
  .rightSection .quantity {
    font-size: 10px;
  }
  .userImage {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
}

.dropdownArrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  padding: 5px;
  margin-top: 5px;
  align-self: flex-end;
  transition: color 0.3s ease;
}

.dropdownArrow:hover {
  color: #ff8e4d;
}

.shippingAddressSheet {
  background-color: #f9f9f9;
  border-radius: 0 0 12px 12px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #eee;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.shippingAddressContent {
  position: relative;
  padding: 2rem;
  /* width: 100%; */
}

.shippingAddressContent h4 {
  margin-top: 0;
  color: #000;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.addressDetails {
  max-width: 60%;
  padding-right: 1rem;
}

.addressDetails p {
  margin: 0;
  color: #333;
  font-size: 0.95rem;
  line-height: 1.4;
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .addressDetails {
    grid-template-columns: 1fr;
  }

  .shippingAddressSheet {
    padding: 0.75rem;
  }

  .addressDetails p {
    font-size: 0.8rem;
  }
}

.noAddressMessage {
  padding: 10px 0;
  color: #666;
  font-style: italic;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 30px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 200px;
}

.videoButton {
  background-color: #ff8e4d;
  color: white;
}

.ledgerButton {
  background-color: #ff8e4d;
  color: white;
}

.buttonIcon {
  font-size: 1.2rem;
}

.actionButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .actionButtons {
    position: static;
    transform: none;
    margin-top: 1.5rem;
    padding: 0 1rem;
  }

  .actionButton {
    width: 100%;
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }

  .buttonIcon {
    font-size: 1rem;
  }

  .shippingAddressContent {
    padding: 1rem;
  }

  .addressDetails {
    max-width: 100%;
    padding-right: 0;
    font-size: 0.9rem;
  }

  .shippingAddressContent h4 {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
  .orderButton{
    font-size: 0.6rem;
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .actionButton {
    font-size: 0.75rem;
    padding: 0.5rem 0.8rem;
  }

  .buttonIcon {
    font-size: 0.9rem;
  }

  .shippingAddressContent {
    padding: 0.8rem;
  }

  .addressDetails {
    font-size: 0.8rem;
  }
}
