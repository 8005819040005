.footer {
  background: linear-gradient(90deg, #000000, #FF8E4D);
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
}

.footerLeft {
  flex: 1;
  max-width: 500px;
  text-align: justify;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  /* justify-content: center; */
}

.logo , .logo1 {
  height: 50px;
}

.divider {
  height: 30px;
  width: 2px;
  background-color: #fff;
}

.description {
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 300px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: 1rem;
}

.footerRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 300px;
}

.contactTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contactLink {
  font-size: 0.9rem;
  color: #fff;
  text-decoration: none;
  font-weight: 600
  /* margin-bottom: 0.5rem; */
}

.socialIcons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.socialIcons a {
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-top: 1px solid #fff;
  padding-top: 1rem;
  font-weight: 400;
}

.footerLinks {
  display: flex;
  gap: 1rem;
}

.footerLinks a {
  font-size: 0.9rem;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem;
    gap: 1rem;
    background: linear-gradient(90deg, #000000, #FF8E4D);
  }

  .footerContent {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
  }

  .footerLeft {
    flex: 2;  /* Give more space to the left content */
  }

  .description {
    font-size: 0.4rem;  /* Reduced from 0.65rem */
    line-height: 1.2;
    margin-top: 0.5rem;
    max-width: none;
  }

  .footerRight {
    flex: 1;
    text-align: right;
  }

  .contactTitle {
    font-size: 0.4rem;  /* Reduced from 0.8rem */
    margin-bottom: 0.25rem;
  }

  .contactLink {
    font-size: 0.4rem;  /* Reduced from 0.65rem */
    margin-bottom: 0.25rem;
  }

  .socialIcons {
    gap: 0.4rem;  /* Reduced gap */
    margin-top: 0.4rem;
    justify-content: flex-end;
  }

  .socialIcons a {
    font-size: 0.4rem;  /* Match other text sizes */
  }

  .socialIcons svg {  /* Add specific control for social icons */
    width: 0.4rem;
    height: 0.4rem;
  }

  .socialIcons img {  /* In case icons are images */
    width: 0.4rem;
    height: 0.4rem;
  }

  .footerBottom {
    margin-top: 1rem;
    padding-top: 0.5rem;
    font-size: 0.4rem;  /* Reduced from 0.65rem */
  }

  .footerLinks {
    gap: 1rem;
  }

  .footerLinks a {
    font-size: 0.4rem;  /* Reduced from 0.65rem */
  }

  .logo, .logo1 {
    height: 25px;  /* Even smaller logos */
  }

  .divider {
    height: 20px;  /* Smaller divider */
  }
}