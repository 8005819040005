.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-size: cover;
    background-position: center;
    height: auto;
    min-height: 100vh;
    box-sizing: border-box;
    overflow: hidden; 
  }
  
  .flexContainer {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: flex-end; 
    gap: 80px; 
    max-width: 1200px; 
    width: 100%;
    box-sizing: border-box;
    padding: 3rem 0;
  }
  
  .qrSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 350px; 
    flex-shrink: 0;
  }
  
  .qrImage {
    /* max-width: 100%; */
    width: 400px;
    height: 400px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .qrText {
    font-size: 1.34rem;
    color: #000;
  }
  
  .contentSection {
    flex: 1; 
    max-width: 600px; 
    width: 100%;
  }
  
  .heading {
    font-size: 3.8rem;
    margin-bottom: 40px;
    text-align: left;
    color: #000 !important;
  }
  
  .stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px; 
    max-width: 600px;
    font-size: 18px
  }
  
  .step {
    display: flex;
    flex-direction: column;
  }
  
  .stepTitle {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .stepDescription {
    margin: 0;
    color: #000;
    text-align: justify;
    line-height: 1.6;
  }
  
  .ledgerButton {
    background-color: #FF8E4C;
    color: white;
    padding: 18px 36px;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    /* width: 100%; */
    max-width: 350px;
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    font-weight: 600;
  }
  
  .ledgerButton:hover {
    background-color: #e67e43;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }

    .flexContainer {
      flex-direction: column-reverse;
      gap: 30px;
      padding: 1.5rem 0;
    }

    .qrSection {
      width: 100%;
    }

    .qrImage {
      width: 300px;
      height: 300px;
    }

    .heading {
      font-size: 2.8rem;
      text-align: center;
      margin-bottom: 25px;
    }

    .stepsContainer {
      font-size: 16px;
      gap: 15px;
    }

    .qrText {
      font-size: 1.2rem;
    }

    .ledgerButton {
      max-width: 200px;
      /* width: 100%; */
      padding: 15px 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  @media (max-width: 400px) {
    .container {
      padding: 0 2rem;
    }

    .flexContainer {
      gap: 20px;
      padding: 1rem 0;
    }

    .qrImage {
      width: 250px;
      height: 250px;
    }

    .heading {
      font-size: 2.2rem;
      margin-bottom: 20px;
    }

    .stepsContainer {
      font-size: 12px;
      gap: 12px;
    }

    .qrText {
      font-size: 1rem;
    }

    .stepDescription {
      line-height: 1.4;
    }

    .ledgerButton {
      padding: 12px 24px;
      font-size: 14px;
    }
  }
  