.aboutSection {
    position: relative;
    background: linear-gradient(
        rgba(142, 79, 30, 0.65),
        rgba(102, 57, 12, 0.85)
      ),
      url('../../../assets/amritjal_hp/aboutImage.jpeg') center/cover;
    /* min-height: 100vh;  */
    padding: 4rem 2rem;
    color: white;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.heading {
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    /* margin-bottom: 3rem; */
    color: white !important; /* Change heading color to white */
}

.contentWrapper {
    display: flex;
    gap: 4rem;
    margin-bottom: 4rem;
    align-items: flex-start;
}

.paragraph {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.6;
}

.divider {
    width: 2px;
    background-color: white;
    align-self: stretch;
}

.cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ensures responsive layout */
    gap: 1.5rem;
    margin-top: 2rem;
    justify-items: center; /* Center the cards horizontally */
}

.card {
    background: rgba(255, 225, 207, 0.34);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease;
    min-width: 250px; /* Ensure cards have a minimum size */
    min-height: 250px; /* Ensure cards have a minimum height */
    max-width: 250px; /* Restrict cards from growing too large */
    max-height: 250px; /* Restrict cards from growing too large */
    box-sizing: border-box; /* Include padding in width/height calculation */
    display: flex;
    justify-content: center;
    align-items: center;
}

.card:hover {
    transform: translateY(-5px);
}

.card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    color: white; /* Ensure the text inside the cards is white */
}

@media (max-width: 768px) {
    .contentWrapper {
        flex-direction: row;
        gap: 2rem;
        flex-wrap: wrap;
        position: relative; /* For absolute positioning of divider */
    }

    .paragraph {
        flex: 1 1 calc(50% - 1rem);
        min-width: 0;
        font-size: 0.56rem; /* Further reduce font size */
        line-height: 1.3; /* Adjust line height */
    }

    .divider {
        display: block; /* Show divider */
        position: absolute;
        left: 50%;
        height: 100%;
        width: 1px; /* Thinner line for mobile */
        background-color: white;
        transform: translateX(-50%);
    }

    .heading {
        font-size: 2rem; /* Slightly reduce font size */
        margin-bottom: 2rem;
        white-space: nowrap; /* Prevent text from wrapping */
    }

    .cardsContainer {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .card {
        min-width: auto;
        min-height: 150px;
        max-width: none;
    }

    .card h3 {
        font-size: 1.2rem;
    }
}

@media (max-width: 400px) {
    .cardsContainer {
        padding: 0 1rem; /* Add padding to prevent sticking to edges */
        grid-template-columns: repeat(2, 1fr);
        gap: 0.8rem; /* Reduce gap for very small screens */
    }

    .card {
        min-height: 120px; /* Reduce height for very small screens */
        padding: 1rem; /* Reduce padding */
    }

    .card h3 {
        font-size: 1rem; /* Slightly smaller font for very small screens */
    }

    .heading {
        font-size: 1.8rem; /* Further reduce font size for very small screens */
    }
}
