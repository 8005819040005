.testimonialsContainer { 
  padding: 0 2rem;
  margin: 0 auto;
  background-color: #fff;
} 

.testimonialsTitle {
  font-size: clamp(2rem, 8vw, 15rem); 
  font-weight: 700;
  color: rgb(255, 228, 218);
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #FF8E4C, #000000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 25%;
}


.carouselContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.carouselContent {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 1000px;
}

.navButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top:100px;
}

.navIcon {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}

.mainSlide {
  flex: 1;
  text-align: center;
}

.mainImage {
  /* border-radius: 8px; */
  width: 458px;
  height: 471px;
  margin-bottom: -5px;
}

.thumbnail {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.thumbnail:hover {
  opacity: 0.8;
}

.thumbnailImage {
  /* border-radius: 8px; */
  width: 208px;
  height: 208px;
}

.thumbnailRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonialInfo {
  margin-bottom: 1rem;
  text-align: left;
  max-width: 210px;
}

.name {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.location {
  color: #666;
  margin: 0.25rem 0;
}

.text {
  font-size: 1rem;
  line-height: 1.6;
}


.carouselContent {
  align-items: flex-end;
  margin-bottom: 40px;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.testimonialInfo {
  margin-bottom: 1rem;
}


.mainSlide {
  align-self: center;
}
@media (min-width: 1200px) {
  .testimonialsTitle {
    font-size: 14rem; 
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  /* Hide desktop layout */
  .desktopLayout {
    display: none;
  }

  /* Mobile layout visibility */
  .mobileLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .thumbnailMain{
    display: flex ;
        gap: 4px;
        /* margin-top: -51px;
        margin-bottom: 20px; */
  }

  .carouselContainer {
    flex-direction: column;
    align-items: center;
  }

  .carouselContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .mainSlide {
    text-align: center;
  }

  .mainImage {
    width: 280px;
    height: 287px;
    object-fit: cover;
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align images to the right */
    gap: 0px;
    margin-top: -21px;
    width: 280px;
  }

  .testimonialInfo {
    flex: 1;
    text-align: left; /* Ensure text is aligned to the left */
    margin-right: 1rem; /* Add spacing from the images */
  }

  .thumbnail {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column; /* Stack images vertically */
    align-items: flex-end; /* Align images to the right */
    gap: 1rem;
  }

  .thumbnailImage {
    width: 50px;
    height: 60px;
  }

  .testimonialsTitle {
    font-size: 3.5rem;
    margin: 0;
  }
  .testimonialsContainer{
    padding: 0 1rem;
  }

  .navButton {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 10;
    /* background-color: rgba(255, 255, 255, 0.8); Slight background for visibility */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); */
  }

  .navButton.prev {
    left: -10px;
  }

  .navButton.next {
    right: -10px;
  }

  .navIcon {
    font-size: 3rem;
    font-weight: 200;
  }
  .text, .location{
    font-size: 12px;
    text-align: left;
    margin-top: 0;
  }
  .name{
    font-size: 12px;
  }
}

/* Ensure desktop layout remains intact */
@media (min-width: 769px) {
  .desktopLayout {
    display: flex;
  }

  .mobileLayout {
    display: none;
  }
  .thumbnailMain{
    display: flex;
    gap: 24px;
    margin-top: -51px;
    margin-bottom: 20px;
  }
}
