/* General styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Container styles */
  .gateway_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Header styles */
  .gateway_header {
    margin-bottom: 20px;
  }
  
  .gateway_title {
    font-size: 2rem;
    color: #ff6f61;
    margin: 0;
  }
  
  .gateway_description {
    font-size: 1rem;
    color: #555;
    margin: 10px 0 0;
  }
  
  /* Form container styles */
  .form_container {
    margin: 20px 0;
  }
  
  iframe {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Footer styles */
  .gateway_footer {
    margin-top: 20px;
    font-size: 0.875rem;
    color: #888;
  }