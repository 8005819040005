.offerContainer {
    position: relative;
    min-height: 100vh;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px; 
    background-image: url("../../../../assets/amritjal_hp/offerCard.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
.offerContainerHoli {
    position: relative;
    min-height: 100vh;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px; 
    background-image: url("../../../../assets/holiprasad/Vrindavan_offer_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .offerContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .offerContent {
    position: relative;
    max-width: 42rem;
    width: 100%;
    padding: 24px; 
    color: white; 
    display: flex;
    flex-direction: column;
    gap: 16px; 
  }
  
  .offerProgress {
    text-align: center;
  }
  
  .offerTitle {
    font-size: 3rem;
    color: white;
    margin: 1rem 0;
  }
  
  .progressBar {
    width: 100%;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 28px;
    overflow: hidden;
  }
  
  .progressBarFill {
    height: 100%;
    background-color: #ffffff;
    transition: width 0.3s ease;
  }
  
  .offerPrice {
    text-align: center;
    margin-bottom: 16px; 
  }
  
  .offerPriceOriginal {
    font-size: 2rem;
    text-decoration: line-through;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: -30px; 
  }
  
  .offerPriceSpecial {
    font-size: 2.5rem;
    font-weight: bold;
    color: #FF8E4C;
    margin-bottom:-60px;
  }
  
  .offerTimer {
    text-align: center;
    padding: 16px;
    margin-top: 8px; 
  }
  
  .offerTimerText {
    font-size: 1.875rem;
    margin-bottom: 20px; 
    color: #fff;
    font-weight: 600;
  }
  
  .timerDisplay {
    display: flex;
    justify-content: center;
    gap: 12px; 
    align-items: center;
  }
  
  .timerBox {
    background-color: #f0f0f0;
    color: #000;
    padding: 3rem 1rem; 
    border-radius: 28px; 
    min-width: 100px; 
    font-size: 3rem; 
    font-weight: bold;
    text-align: center;
  }
  
  .timerSeparator {
    font-size: 3rem; 
    font-weight: bold;
  }
  
  .shopButton {
    background-color: #FF8E4C; 
    color: white;
    border: none;
    border-radius: 50px;
    padding: 16px 32px;
    font-size: 1.125rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
    display: block;
  }
  
  .shopButton:hover {
    background-color: #FF8E4C; 
  }
  .offerPriceSpecial.crossed {
    text-decoration: line-through;
    color: rgba(255, 255, 255, 0.6);
  }
  .offerPriceOriginal.active {
    color: #FF8E4C;
    font-weight: bold;
    text-decoration: none;
  }
  .offerTimerText.expired {
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
  }
  .shopButton:disabled {
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
    
    
  
  /* Media Queries */
  @media (max-width: 768px) {
    .offerContent {
      padding: 16px;
    }
  
    .offerTitle {
      font-size: 1.4rem;
    }
  
    .offerPriceSpecial {
      font-size: 1.4rem;
    }
  
    .timerBox {
      min-width: 60px;
      font-size: 2rem;
      padding: 8px;
    }
  
    .timerSeparator {
      font-size: 2rem;
    }
    .offerPriceOriginal{
      font-size: 1.2rem;
      margin-bottom: 0;
      font-weight: 600; 
    }
    .offerContainerHoli{
      min-height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .offerContainer {
      padding: 8px;
      min-height: auto;
    }
  
    .timerBox {
      min-width: 50px;
      font-size: 1.75rem;
      padding: 2rem 1rem;
    }
  
    .shopButton {
      padding: 12px 24px;
      font-size: 1rem;
    }
  }
  
.offerCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.priceSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.discountedPrice {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.originalPrice {
  font-size: 1.2rem;
  text-decoration: line-through;
  color: #666;
}

.salesInfo {
  font-size: 1rem;
  color: #333;
}
  