.container {
    /* min-height: 100vh; */
    background: #000000;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  
  .title {
    font-size: 4rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: 2px;
    color: #fff !important;
  }
  
  .tripDetails {
    display: flex;
    background: white;
    border-radius: 50px;
    padding: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 800px;
  }
  
  .detailItem {
    flex: 1;
    padding: 1rem;
    text-align: center;
    color: black;
  }
  
  .detailLabel {
    color: #DB6922 !important;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .detailValue {
    color: #DB6922;
    font-weight: 600;
    font-size: 1.25rem;
  }
  
  .dateContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 800px;
  }
  
  .dateItem {
    background: white;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    color: #DB6922 !important;
  }
  
  .disclaimer {
    text-align: center;
    color: #999;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    max-width: 600px;
  }
  
  .applyButton {
    background: #DB6922;
    color: white;
    padding: 1rem 3rem;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .applyButton:hover {
    background: #c55a1b;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .title {
      font-size: 2.1rem;
      margin-bottom: 2rem;
    }
  
    .tripDetails {
      /* flex-direction: column; */
      border-radius: 25px;
      align-items: center;
      justify-items: center;
      padding: 0;
    }
  
    .dateContainer {
      /* flex-direction: column; */
      align-items: center;
    }
  
    .dateItem {
      /* width: 90%; */
      text-align: center;
      font-size: 0.6rem;
    }
    .detailLabel {
      color: #666;
      font-size: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .detailValue {
      font-size: 1rem;
    }
    .disclaimer{
      margin-bottom: 0;
      font-size: 0.6rem;
    }
    .applyButton{
      padding: 0.5rem 2rem;
      font-size: 0.7rem;
      margin-top: 1rem;
    }
  }