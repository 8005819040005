.benefitsSection {
    width: 100%;
    background-image: url('../../../../assets/holiprasad/benefits_vrindavan_bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 30px 0px;
    position: relative;
  }
  
  .benefitsSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1;
  }
  
  .benefitsContainer {
    position: relative;
    z-index: 2;
    /* max-width: 1200px;
    margin: 0 auto; */
  }
  
  .benefitsTitle {
    color: white !important;
    font-size: 8rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem;
    letter-spacing: 2px;
    /* margin-bottom: 55px; */

  }
  
  .benefitsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 30px 60px; /* Add padding to prevent overflow */
    box-sizing: border-box; /* Ensure padding is accounted for */
  }
  
  .benefitCard {
    background-color: #FFFAE4;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  
  .benefitCard h2 {
    color: #FF8E4D;
    font-size: 40px;
    margin-bottom: 15px;
    font-weight: 600;
    margin: 0;
  }
  
  .benefitCard p {
    color: #333;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .benefitsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .benefitsGrid {
      grid-template-columns: repeat(1, 1fr); /* Two cards per row */
      gap: 10px; /* Reduce gappadding: 0 20px; /* padding: 0 2rem; */
      padding: 0 20px;
    }
  
    .benefitCard {
      padding: 13px; /* Reduce padding for smaller cards */
    }
  
    .benefitsTitle {
      font-size: 3.5rem; /* Adjust font size */
      margin: 0.5rem;
      margin-bottom: 30px;
    }
    .benefitsContainer{
      width: 100%;
      margin: 0;
    }
    .benefitCard h2{
    font-size: 16px; /* Adjust font size */
    margin-bottom: 0.5rem
    }

    .benefitCard p{
      font-size: 14px;
      /* max-width: 200px; */
      margin-top: 0.5rem;
    }
  }

  