/* Main container */
.container {
  font-family: "Arial", sans-serif;
  padding: 18px;
  margin-top: 0px;
}

/* Title styling */
.title {
  text-align: center;
  color: #fff !important;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Products container */
.productsContainer {
  width: 100%;
  margin: 0 auto;
}

/* Products grid */
.productsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

/* Product item */
.productItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding: 15px; */
  border-right: 1px solid white;
  border-bottom: 1px solid white;
}

/* Product image container */
.productImageContainer {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

/* Product image */
.productImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Product name */
.productName {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.3;
  color: #fff !important;
}

/* Media query for tablet and desktop */
@media (min-width: 768px) {
  .container {
    margin-top: -120px;
  }

  .productsGrid {
    grid-template-columns: repeat(5, 1fr);
    gap: 0px;
  }

  /* Remove border-right from the last element of each row */
  .productItem:nth-child(5n) {
    border-right: none;
  }

  /* Remove border-bottom for all items in the second row */
  .productItem:nth-child(n+6):nth-child(-n+10) {
    border-bottom: none;
  }

  .productImageContainer {
    width: 180px;
    height: 180px;
  }

  .productName {
    font-size: 1rem;
    color: #fff !important;
  }
}

/* Media query for mobile */
@media (max-width: 767px) {
  /* .productName {
    display: none;
  } */
  .productsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }

  /* Remove border-right from the last element in each column */
  .productItem:nth-child(2n) {
    border-right: none;
  }

  /* Remove border-bottom for the last element of both columns */
  .productItem:nth-last-child(-n+2) {
    border-bottom: none;
  }
  .title{
    font-size: 16px;
  }
}
