.container {
  position: relative;
  height: auto;
  width: 100%;
  background: linear-gradient(to bottom right, #000000, #ff8e4c);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.heading {
  font-size: clamp(1.5rem, 4vw, 3rem);
  font-weight: 300;
  letter-spacing: 0.1em;
  color: white;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  gap: clamp(0.5rem, 1vw, 1rem);
}

.heading span {
  display: inline-block;
  font-size: clamp(1rem, 6vw, 5rem);
  font-weight: 400;
  color: white;
}

.separator {
  color: rgba(255, 255, 255, 0.7);
}
.separator_divine {
  color: #FF8E4D  !important;
}

.highlight {
  color: #FF8E4D  !important;
  font-weight: 500
}

.divine {
  color: #f97316; 
  text-shadow: 0 0 10px #f97316, 0 0 20px #f97316, 0 0 30px #f97316;
}

.imageContainer {
  position: relative;
  width: 90%;
  max-width: 28rem;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.image {
  object-fit: contain;
  width: 100%;
  height: auto;
  position: relative;
}

@media (max-width: 768px) {
  .heading{
    margin-top: 30px;
  }
}