.benefitsContainer {
  background-color: #000;
  min-height: 100vh;
  padding: 40px 20px;
  color: white;
}

.benefitsTitle {
  text-align: center;
  font-size: clamp(26px, 5vw, 64px); /* Dynamically adjusts font size */
  margin-bottom: 40px;
  color: white !important;
}

.benefitsGrid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures consistent horizontal alignment */
  gap: 20px;
  padding: 20px;
}

.cardRow {
  display: grid;
  grid-template-columns: repeat(2, 500px);
  gap: 20px;
  justify-content: center;
}

.cardRow .benefitCard {
  height: 250px;
}

.singleColumnRow {
  display: grid;
  grid-template-columns: 700px 300px;
  gap: 20px;
  justify-content: center;
}

.singleColumnRow .benefitCard {
  height: 250px;
}

.thirdRow {
  display: grid;
  grid-template-columns: repeat(2, 500px);
  gap: 20px;
  justify-content: center;
}

.thirdRow .benefitCard {
  height: 250px;
}

.benefitCard {
  background-color: white;
  border-radius: 70px;
  padding: 50px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 250px; /* Fixed height for larger screens */
  transition: height 0.2s ease-in-out; /* Smooth scaling for height changes */
}

.benefitCard.orange {
  background: linear-gradient(90deg, #D65C20, #E27E25);
  color: white;
}

.benefitCard h3 {
  color: #D75E21;
  font-size: clamp(1.4rem, 2.5vw, 1.5rem); /* Adjusts based on screen size */
  margin-bottom: 15px;
}

.benefitCard.orange h3 {
  color: white;
}

.benefitCard p {
  font-size: clamp(1rem, 2vw, 1.375rem); /* Responsive paragraph text */
  line-height: 1.5; 
  color: #D75E21;
}
.benefitCard .descriptionColor{
  color: #fff;
}
/* Movement card */
.movementCard {
  background: linear-gradient(90deg, #D65C20, #E27E25);
  color: white;
  border-radius: 70px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.movementCard h2 {
  font-size: clamp(1.5rem, 3vw, 2rem); /* Responsive font size */
  margin-bottom: 15px;
  color: white;
}

.movementCard p {
  font-size: clamp(1rem, 2.5vw, 1.1rem); /* Responsive font size */
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .cardRow,
  .singleColumnRow,
  .thirdRow {
    grid-template-columns: minmax(auto, 500px);
  }

  .movementCard {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .benefitsGrid {
    padding: 10px;
  }

  .cardRow,
  .singleColumnRow,
  .thirdRow {
    grid-template-columns: 1fr; /* Single column layout for narrow screens */
  }

  .movementCard {
    max-width: 100%; /* Full width for small screens */
    padding: 20px;
  }

 .benefitCard {
   border-radius: 40px;
   padding: 20px;
   height: auto;
  }
  .cardRow .benefitCard {
    height: auto;
  }
  .singleColumnRow .benefitCard {
    height: auto;
  }
  .thirdRow .benefitCard {
    height: auto;
  }
  
  
}
