/* General Styles */
.container {
  /* min-height: 100vh; */
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.layoutWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 140px 0;
  position: relative;
  /* flex: 1; */
}

/* Left Section - Instant Calm */
.leftSection {
  max-width: 350px;
  text-align: left;
}

.leftSection h1 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #000 !important;
}

.leftSection p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

/* Center Section - Product Image */
.centerSection {
  position: relative;
  display: flex;
  align-items: center;
}

.productImage {
  width: 450px;
  height: auto;
  object-fit: contain;
  z-index: 2;
}

/* Rose Fragrance Text */
.roseFragrance {
  position: absolute;
  left: -350px;
  bottom: 130px;
  color: #c70000;
  font-size: 35px;
  font-style: italic;
  font-weight: bold;
  text-align: left;
  z-index: 3;
}

/* Right Section - Energize */
.rightSection {
  max-width: 350px;
  text-align: left;
}

.rightSection h2 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: #000;
}

.rightSection p {
  font-size: 18px;
  color: #666;
  margin-bottom: 2rem;
}

.orderButton {
  background: #000;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}

/* Bottom Section - Stay Connected */
.bottomSection {
  text-align: left;
  padding: 20px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bottomSection h2 {
  font-size: 50px;
  line-height: 1.1;
  margin-bottom: 1rem;
  color: #000;
  text-align: left;
  align-self: center;
  max-width: 450px; /* Match product image width */
}

.bottomSection p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
  max-width: 450px; /* Match product image width */
  margin: 0;
  text-align: left;
  align-self: center;
}

/* Responsive styles - Modified to maintain the layout */
@media (max-width: 1274px) {
  .layoutWrapper {
    padding: 60px 80px 0;
  }
  
  .roseFragrance {
    left: -250px;
    font-size: 30px;
  }
  
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 45px;
  }
  
  .productImage {
    width: 400px;
  }
}

@media (max-width: 1119px) {
  .layoutWrapper {
    padding: 50px 40px 0;
    justify-content: space-between;
  }
  
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 40px;
  }
  
  .leftSection p,
  .rightSection p,
  .bottomSection p {
    font-size: 16px;
  }
  
  .roseFragrance {
    left: -200px;
    bottom: 100px;
    font-size: 25px;
  }
  
  .productImage {
    width: 350px;
  }
}

@media (max-width: 900px) {
  .layoutWrapper {
    justify-content: space-between;
    padding: 30px 20px 0;
  }
  
  .leftSection,
  .rightSection {
    max-width: 30%;
  }
  
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 32px;
  }
  
  .leftSection p,
  .rightSection p,
  .bottomSection p {
    font-size: 14px;
  }
  
  .productImage {
    width: 280px;
  }
  
  .roseFragrance {
    left: -150px;
    bottom: 80px;
    font-size: 20px;
  }
  
  .orderButton {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .layoutWrapper {
    justify-content: space-between;
    padding: 20px 15px 0;
  }
  
  .leftSection,
  .rightSection {
    max-width: 28%;
  }
  
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 24px;
  }
  
  .leftSection p,
  .rightSection p,
  .bottomSection p {
    font-size: 12px;
    line-height: 1.4;
  }
  
  .productImage {
    width: 220px;
  }
  
  .roseFragrance {
    left: -120px;
    bottom: 60px;
    font-size: 16px;
  }
  
  .orderButton {
    padding: 8px 16px;
    font-size: 12px;
  }
  
  .bottomSection h2 {
    max-width: 220px;
  }
  
  .bottomSection p {
    max-width: 220px;
  }
}

@media (max-width: 480px) {
  .layoutWrapper {
    padding: 15px 10px 0;
  }
  
  .leftSection,
  .rightSection {
    max-width: 30%;
  }
  
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  
  .leftSection p,
  .rightSection p,
  .bottomSection p {
    font-size: 10px;
    line-height: 1.3;
    margin-bottom: 0.45rem;
  }
  
  .productImage {
    width: 160px;
  }
  
  .roseFragrance {
    left: -100px;
    bottom: 45px;
    font-size: 10px;
  }
  
  .orderButton {
    padding: 4px 8px;
    font-size: 6px;
  }
  
  .bottomSection {
    padding: 10px 0 10px;
  }
  
  .bottomSection h2 {
    max-width: 160px;
  }
  
  .bottomSection p {
    max-width: 160px;
  }
}

/* Additional media query for very small screens */
@media (max-width: 360px) {
  .leftSection h1,
  .rightSection h2,
  .bottomSection h2 {
    font-size: 16px;
  }
  
  .leftSection p,
  .rightSection p,
  .bottomSection p {
    font-size: 8px;
    line-height: 1.2;
  }
  
  .productImage {
    width: 120px;
  }
  
  .roseFragrance {
    left: -70px;
    bottom: 30px;
    font-size: 10px;
  }
  
  .orderButton {
    padding: 4px 10px;
    font-size: 8px;
  }
}
