.body {
  font-family: "Inter";
}

.ayodhya {
    /* background-color: #fff1ea; */
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Center align items */
    padding: 0rem 2rem; /* Updated padding */
  }
  
  .title {
    width: auto;
    font-size: 1.5em;
    font-weight: bold;
    color: #ff8e4d;
    margin: 10px 0px;
    text-align: start; /* Center align text */
  }
  
  .subtitle {
    width: auto;
    font-size: 1em;
    color: #666;
    text-align: start; /* Center align text */
  }
  
  .iconTextWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1em;
  }
  
  .mainContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    margin: 0 1rem;
  }
  
  .features {
    width: 100%;
    min-height: 100px; /* Occupy full width */
    margin-bottom: 20px;
    margin-top: -25px;
  }
  
  .listing {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 1em; /* Add padding to create space from the corners */
  }
  
  .pujaImage {
    width: 1000px;
    height: 50vh;
    border-radius: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    object-fit: cover;
  }
  /* styles.css */
  
  .textContainer {
    margin-top: 20px;
  }
  .colorText {
    color: blue; /* or any other color you prefer */
  }
  
  .reviewsTitle {
    font-size: 20px;
    display: block;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: start;
  }
  
  .scrollContainer {
    width: 90%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 10px; /* Adjust padding as needed */
  }
  
  
  .back {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Center align content */
    gap: 0.5em;
    margin-bottom: 1em;
  }
  
  .backButton {
    cursor: pointer;
    color: #666;
    text-align: center; /* Center align text */
  }
  
  .bookbutton_parent {
    display: flex;
    justify-content: center;
  }

  .bookButton {
    width: 550px;
    padding: 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    background: linear-gradient(to right, #ffb64d, #ff714e);
    color: white;
    text-align: center;
    margin: 30px;
  }
  .tabs {
    width: 100%; 
    display: flex;
    justify-content: space-between; /* Distribute space evenly between tabs */
    margin-bottom: -3rem; /* Add space below tabs */
    padding: 0.5rem; /* Add padding to the container */
  }
  .line {
    margin-top: 10px; /* Position the line relative to the button */
    left: 0; /* Align the line to the left of the button */
    height: 4px; /* Set the height of the line */
    width: 30%; /* Make the line span the full width of the button */
    background-color: #ffB64d; /* Set the color of the line to white */
  
  }
  .line1 {
    margin-top: 10px; /* Position the line relative to the button */ 
    left: 0; /* Align the line to the left of the button */
    height: 4px; /* Set the height of the line */
    width: 30%; /* Make the line span the full width of the button */
    background-color: transparent; /* Set the color of the line to white */
  }
  .tabContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4); /* Each column takes one quarter of the width */
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    font-size: 1em;
  }
  
  /* Update styles for individual tabs */
  .tab {
    width: 100%; 
    cursor: pointer;
    font-weight: bold;
    border: none;
    outline: none;
    background-color: transparent;
    position: relative;
  }
  
  .activeTab {
    width: 100%; 
    color: #000000;
    font-weight: bold;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  .divider {
    width: 100%;
    margin-top: 6px;
    height: 1px;
    background-color: #00000026; /* You can change the color as needed */
  }
  .image1 {
    z-index: 1;
  }
  .image2 {
    z-index: 2;
    margin-left: -3%;
  }
  .image3 {
    z-index: 3;
    margin-left: -3%;
  }

  .text1 {
    font-size: 20px; /* Original font size */
    margin-left: 12px;
  }
  .image0 {
    position: 'absolute';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: 'cover';
  }
  
  .container {
      display: flex;
      align-items: center;
      width: 95%;
    }
    .colorText
    {
      color: #FF8E4C;
    }
  
  footer {
    margin-top: auto;
  }
  
  .centralAlign {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Optional: If you want the components to be stacked vertically */
  }
  
  .carouselContainer {
    width: 80%;  /* Adjust the container width as needed */
    margin: 0 auto;
  }
  
  .activeSection {
    background-color: #f0f0f0; /* Highlight color */
    border-left: 5px solid #007bff; /* Highlight border */
    padding-left: 10px; /* Adjust padding */
  }
  
  .highlightBox {
    border: 2px solid gold;
    padding: 10px;
    transition: border 0.5s ease-in-out;
    box-sizing: border-box;
    width: 100%;
  }
  
  .noBorder {
    border: none;
  }
  
  .hidden {
    opacity: 0;
  }

  .benefits_list {
    display: flex; /* Ensures horizontal layout */
    overflow-x: auto; /* Allows horizontal scrolling */
    padding: 10px;
    list-style-type: none;
    gap: 1rem;
    align-items: center;
  }
  
  .benefit_card {
    width: 30%;
    display: flex;
    align-items:flex-start;
    padding: 10px;
    flex-shrink: 0; /* Prevents shrinking */
  }
  
  .benefit_image {
    width: 100px !important;
    height: 100px !important;
    border-radius: 8px;
    object-fit: 'cover';
    margin-right: 5px;
  }
  
  .benefit_text {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .benefit_title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .benefit_description {
    font-size: 18px;
    color: #666;
    margin: 5px 0 0;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    line-height: 1.5;
  }

  .offerings_container {
    display: flex;
    overflow-x: auto;
    padding: 1rem;
    gap: 1rem;
    list-style-type: none;
    justify-content: left;
    flex-direction: row;
}
  
  .offering_card {
    flex: 0 0 calc(33.33% - 1rem); /* Keep your existing flex settings */
    max-width: 450px; /* Set a maximum width for the card */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px; /* Ensures card doesn't shrink too much */
}
  
  .offering_image {
    width: 100%;
    border-radius: 8px;
    object-fit: fill;
  }
  
  .offering_content {
    padding: 0 1rem;
    text-align: center;
  }
  
  .offering_title {
    font-size: 16px;
    font-weight: bold;
    margin: 0.5rem 0;
    color: #333;
    text-align: left;
  }
  
  .offering_description {
    font-size: 14px;
    color: #666;
    text-align: left;
    margin: 0.5rem;
  }
  
  .book_button {
    padding: 0.5rem 1rem;
    background-color: #ff8e4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 1rem;
    align-items: center;
    font-weight: bold;
  }

  .offering_text_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .offering_text_left {
    font-size: 15px;
    color: black;
    margin-bottom: 0.5em;
  }
  
  .offering_text_right {
    font-size: 14px;
    color: orange;
    font-weight: bold;
    margin-bottom: 0.4em;
  }

  .puja_process_list {
    list-style-type: disc;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    margin: 1rem 0;
  }
  
  .puja_process_item {
    margin-left: 0.5rem;
    font-size: 14px;
    color: #333;
  }

  .pujaParentDiv{
    width:180%;
  }

  .pujadetails_scroll_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0;
    width: 100%;
    align-items: center;
  }
  
  .pujadetails_item {
    display: inline-flex;
    width: auto;
    margin: 0;
  }
  
  .pujadetails_card {
    width: 300px;
    height: 100px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #ffede3;
  }
  
  .pujadetails_card_inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .pujadetails_card_content {
    padding: 5px;
  }
  
  .pujadetails_card_flex {
    display: flex;
    align-items: center;
  }
  
  .pujadetails_icon_wrapper {
    margin-right: 10px;
  }
  
  .pujadetails_icon_container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .pujadetails_icon_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .pujadetails_step_title {
    font-weight: bold;
    margin: 0;
    font-size: 16px;
  }
  
  .pujadetails_step_description {
    margin: 0;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 2px;
    white-space: pre-wrap;
    font-size: 16px;
  }
  .about_puja_description {
    font-size: 20px;
  }
  .limited_slots {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  .slots_row {
    display: flex;
    flex-direction: row;
    gap: 0 1em;
    align-items: center;
  }
  .limited_slots_text_counter {
    font-size: 2.5em;
    color: #FF8E4C;
    font-weight: bold;
  }
  .limited_slots_text {
    font-size: 1.5em;
    color: #FF8E4C;
  }

  .moving_text_link {
    background-image: linear-gradient(45deg, 
      #ff8000, /* Orange */
      #000000, /* black */
      #ffd500, /* Yellow */
      #ffaa00, /* Deep Orange */
      #ff7300, /* Reddish Orange */
      #ff8000  /* Looping back to Orange */
    );
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: moveGradient 3s infinite linear;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    font-size : 1.5em;
  }
  
  @keyframes moveGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  
  
  @media (max-width: 1024px) {
    .mainContent {
      overflow: hidden;
    }
    .ayodhya {
      width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
    .text1 {
      font-size: 1em; /* Original font size */
    }
    .subtitle {
      width: 20em;
      
    }
    .image1 {
      z-index: 1;
    }
    .image2 {
      z-index: 2;
      margin-left: -1.5em;
    }
    .image3 {
      z-index: 3;
      margin-left: -1.5em;
      border-radius: 50%;
    }
    .pujaImage {
      width: 40%;
      height: 30vh;
      border-radius: 1rem;
    }
    .listing {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .listing .pujaName {
      order: 1; /* Change the order to display pujaName below iconTextWrapper */
      margin-top: 1rem; /* Add margin for spacing */
    }
  
    .listing .pujaImage {
      margin-top: 1rem; /* Add margin for spacing */
    }
  
    .header,
    .features,
    .listing {
      padding: 1em; /* Updated padding */
    }
    .pujaDetailsParent{
      width:100%;
      overflow: hidden;
    }
  
  
    .iconTextWrapper {
      display: flex;
      gap: 1em;
      margin-left: -1em;
    }
    .pujaParentDiv{
      width:100%;
    }
    .bookButton {
      width: 20em;
    }
    .container {
      width: 50%;
    }
    .title {
      width: 12em;
    }
    .subtitle {
      width: 20em;
    
    }
    .centralAlign {
      flex-direction: column; /* Ensure vertical stacking on smaller screens */
      padding: 10px; /* Add some padding if needed */
    }
  
    .carouselContainer {
      width: 100%;  /* Full width for mobile devices */
  }
  
  .highlightBox {
    padding: 5px; /* Reduce padding for smaller screens */
    width: calc(100% - 10px);
  }
  
  .scrollContainer {
    width: 100%; /* Use full width on mobile */
    padding: 0 5px; /* Adjust padding for smaller screens */
  }

  .benefits_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0px !important;
    gap: 0.5em !important;
  }

  .benefit_card {
    width: auto;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px !important;
  }

  .benefit_image {
    margin-bottom: 6px;
  }

  .benefit_title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .benefit_description {
    font-size: 14px !important;
    color: #666;
    margin: 0px 0px;
    line-height: 1.8 !important;
  }

  .offering_card {
    flex: 0 0 calc(50% - 1rem); /* 2 cards per row */
  }

  .puja_process_item {
    font-size: 13px;
  }

  .pujadetails_item {
    width: 250px;
  }

  .pujadetails_card {
    width: 200px;
    height: 70px;
    margin-right: 15px;
  }

  .pujadetails_icon_container {
    width: 50px;
    height: 50px;
  }

  .pujadetails_step_description {
    font-size: 11px;
  }

  .about_puja_description {
    font-size: 14px;
  }

  .offerings_container {
    flex-direction: row;
  }

  .image0 {
    position: 'absolute';
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }

  .tabContainer {
    font-size: 0.7em;
  }
  }

  @media (max-width: 767px) {
    .mainContent {
      overflow: hidden;
    }
    .ayodhya {
      width: 100%;
      min-height: 100vh;
      overflow: hidden;
  }
    .text1 {
      font-size: 1em; /* Original font size */
    }
    .subtitle {
      width: 20em;
      
    }
    .image0 {
      position: 'absolute';
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
    }
    .image1 {
      z-index: 1;
    }
    .image2 {
      z-index: 2;
      margin-left: -1.5em;
    }
    .image3 {
      z-index: 3;
      margin-left: -1em;
      border-radius: 50%;
    }
    .pujaImage {
      width: 20em;
      height: 30vh;
      border-radius: 1rem;
    }
    .listing {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .listing .pujaName {
      order: 1; /* Change the order to display pujaName below iconTextWrapper */
      margin-top: 1rem; /* Add margin for spacing */
    }
  
    .listing .pujaImage {
      margin-top: 1rem; /* Add margin for spacing */
    }
  
    .header,
    .features,
    .listing {
      padding: 1em; /* Updated padding */
    }
    .pujaDetailsParent{
      width:100%;
      overflow: hidden;
    }
  
  
    .iconTextWrapper {
      display: flex;
      gap: 1em;
      margin-left: -1em;
    }
    .pujaParentDiv{
      width:100%;
    }
    .bookButton {
      width: 20em;
      align-self: center;
    }
    .container {
      width: 100%;
    }
    .title {
      width: 100%;
      margin-left: 1rem;
      text-align: left;
    }
    .subtitle {
      width: 20em;
    
    }
    .colorText
    {
      color: #FF8E4C;
    }
    .centralAlign {
      flex-direction: column; /* Ensure vertical stacking on smaller screens */
      padding: 10px; /* Add some padding if needed */
    }
  
    .carouselContainer {
      width: 100%;  /* Full width for mobile devices */
  }
  
  .highlightBox {
    padding: 5px; /* Reduce padding for smaller screens */
    width: calc(100% - 10px);
  }
  
  .scrollContainer {
    width: 100%; /* Use full width on mobile */
    padding: 0 5px; /* Adjust padding for smaller screens */
  }

  .benefits_list {
      display: flex;
    justify-content: center;
  }

  .benefit_card {
    width: auto;
    flex-direction: row;
    align-items: flex-start;
  }

  .benefit_image {
    margin-bottom: 10px;
  }

  .benefit_description {
    font-size: 14px;
    color: #666;
    margin: 10px 0 0;
    line-height: 1.6;
  }

  .offering_card {
    flex: 0 0 calc(100% - 1rem); /* 2 cards per row for smaller screens */
  }
  
  .offering_text_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .offering_text_left {
    font-size: 1.2em;
    margin-bottom: 0.5rem;
  }
  
  .offering_text_right {
    font-size: 1.2em;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .puja_process_item {
    font-size: 12px;
  }

  .pujadetails_scroll_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pujadetails_item {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .pujadetails_card {
    width: 80%;
    height: auto;
    margin-right: 10%;
    margin-left: 1%;
  }

  .pujadetails_icon_container {
    width: 40px;
    height: 40px;
  }

  .pujadetails_step_description {
    font-size: 10px;
    padding-right: 10px;
  }
  .about_puja_description {
    font-size: 16px;
  }
  .offerings_container {
    flex-direction: row;
  }

  .tabContainer {
    font-size: 0.7em;
  }

  .bookButton {
    margin: 10px;
  }

  .limited_slots {
    margin-top: 0.5em;
    
  }
  .moving_text_link {
    font-size: 2em;
  }
  }
  