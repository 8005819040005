/* General Container Styles */
.container {
  display: flex;
  flex-direction: row;
  margin: 2rem;
  gap: 2rem;
  align-items: center;
}

/* Left Container (Address Form) */
.leftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */
  padding:0 2rem;
  border-radius: 10px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.leftContainer h1 {
  color: #ff7b25;
  margin-bottom: 1.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputGroup input {
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.saveButton {
  background-color: #ff7b25;
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #eb6a1a;
}

/* Vertical Line */
.verticalLine {
  width: 2px;
  background-color: #e0e0e0;
  margin: 0 1rem;
}

/* Right Container (Saved Addresses) */
.rightContainer {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rightContainer h1 {
  color: #ff7b25;
}

.savedAddresses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addressCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.addressName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff7b25;
}

.addressDetails {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #555;
}

.removeButton {
  margin-top: 1rem;
  background-color: white;
  color: #ff7b25;
  border: 1px solid #ff7b25;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.removeButton:hover {
  background-color: #ffe5d4;
}

.addButton {
  align-self: flex-start;
  background-color: #fff1ea;
  color: #ff7b25;
  border: 1px solid #ff7b25;
  padding: 0.8rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #ffd6c5;
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 1rem;
  }

  .verticalLine {
    display: none;
  }

  .leftContainer, .rightContainer {
    width: 100%;
  }

  .addressCard {
    padding: 0.8rem;
  }

  .addressName {
    font-size: 1.2rem;
  }

  .addressDetails {
    font-size: 0.9rem;
  }
}
