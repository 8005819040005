.checkoutContainer {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.leftSection {
  /* flex: 1; */
  padding-right: 20px;
}

.rightSection {
  flex: 1;
  padding-left: 20px;
}

.verticalLine {
  width: 1px;
  background-color: #ddd;
  margin: 0 20px;
}

.productCard {
  display: flex;
  gap: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.productImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: #E4E4E4;
  height: 134px;
  width: 98px;
  border: 1px solid #DBDBDB;
}

.productDetails {
  flex: 1;
}

.productTitle {
  font-weight: 400;
  margin-bottom: 8px;
  font-size: 20px;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.quantityControlBtn {
  width: 32px;
  height: 32px;
  border: 1px solid #e2e8f0;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.priceBreakdown {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceRow.coupon {
  color: #ff6b00;
}

.priceRow.total {
  font-weight: bold;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.couponSection {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.couponSection input {
  flex: 1;
  padding: 19px 25px;
  border: 1px solid #e2e8f0;
  border-radius: 18px;
  box-shadow: 0px 0px 19px 0px #FF8E4D;
}

.couponSection button {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}

.progressSteps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  color: #CDCDCD;
  position: relative; 
  border-bottom: 3px solid #CDCDCD;
  font-size: 26px;
  width: 150px;
  padding: 0 0 0.4rem 3rem;
  font-weight: 500;
}

.step.active {
  color: #ff6b00;
  border-bottom: 3px solid #ff6b00;
  font-size: 26px;
  font-weight: 500;
}

.verificationSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
/* timer */
.timer {
  text-align: center;
    margin-bottom: 2.5rem;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 1rem;
}

.timer__text {
  display: block;
  margin-bottom: 1.5rem;
}

.timer__digits {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FF8E4C;
}

.timer__number {
  padding: 23px 1.2rem;
  border: 2px solid #FF8E4C;
  border-radius: 20px;
}
.error{
  color: red;
  font-size: 0.8rem;
}

.phoneInput {
  flex: 1;
  padding: 20px 15px;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  font-size: 1rem;
  box-shadow: 0 4px 6px #DBDBDB;
}

.contactInputGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contactPrefix {
  padding: 20px 12px;
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  box-shadow: 0 4px 6px #DBDBDB;
}

.otpInputs {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.contactButton {
  padding: 20px 15px;
  background: #000;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s;
}

.otpInputs input {
  width: 61px;
  height: 82px;
  text-align: center;
  font-size: 1.5em;
  border: 1px solid #DBDBDB;
  border-radius: 15px;
  box-shadow: 0 4px 6px #DBDBDB;
}

.continueBtn {
  width: 100%;
  padding: 18px;
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.resend {
  margin-top: 10px;
  color: #666;
}

/* --------------- */
.viewCoupons {
  /* color: #007bff; */
  cursor: pointer;
  text-decoration: underline;
  margin: 1rem;
  font-weight: 600;
} 

.couponList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.couponCard {
  padding: 0.7rem 1.8rem;
  border-radius: 30px;
  background-color: #ff8e4c;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.couponCard:hover {
  transform: scale(1.05);
}

.couponCard h3 {
  font-size: 0.87rem;
  margin: 0;
}

.couponCard p {
  font-size: 0.7rem;
  margin: 0.5rem 0 0;
}

.selectedCoupon {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.couponSection {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.input {
  flex: 1;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.button {
  padding: 0.75rem 1.5rem;
  background-color: #ff8e4c;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
}

.button:hover {
  background-color: #ff7c2e;
}
/* ---------------- */

/* Address Section */
/* Container and Heading (No changes needed) */
.container {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Form Group (No changes needed) */
.formGroup > * {
  margin-bottom: 1.5rem; 
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem; 
}

.rowThree {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.input,
.textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.textarea {
  height: 100px;
  resize: none;
}

.button {
  width: 100%;
  padding: 0.95rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #000;
  border: none;
  cursor: pointer;
}

/* New Styles for Address List and Cards */

/* Address List (Grid layout for saved addresses) */
.addressList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}

.addressCard {
  padding: 1rem;
  border: 2px solid #FF8E4C;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
}

.addressCard:hover {
  background-color: #fff5e6; /* Light orange background on hover */
}

.cardIcons {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.editIcon,
.deleteIcon {
  cursor: pointer;
  font-size: 1rem;
}

.addAddressCard {
  padding: 1rem;
  border: 2px dashed #FF8E4C;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}

.addAddressCard:hover {
  border-color: #000;
  background-color: #f5f5f5;
}

/* Add a Plus Icon in Add New Address Card */
.addAddressCard::before {
  content: "+";
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.5rem;
}

.otp_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* Main title */
.otp_title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

/* Subtitle */
.otp_subtitle {
  font-size: 14px;
  color: #555;
  opacity: 0.8;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .row {
    grid-template-columns: 1fr; /* Stack inputs vertically on smaller screens */
  }

  .rowThree {
    grid-template-columns: 1fr; /* Stack inputs vertically on smaller screens */
  }

  .addressList {
    grid-template-columns: 1fr; /* Single column for address cards */
  }
  .otp_title {
    font-size: 18px; /* Slightly smaller for mobile */
  }

  .otp_subtitle {
    font-size: 12px; /* Smaller text for mobile */
  }
}


/* Payment section */
.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.payment__title {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Mobile Styles */
.mobileOrderSummary {
  display: none;
}

.summaryToggle {
  display: none;
  width: 100%;
  padding: 15px;
  background: none;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileOrderSummary {
  display: none;
  padding: 0 20px;
  margin-bottom: 20px;
}

.mobileTimerContainer {
  display: none;
  padding: 1rem;
  text-align: center;
}
.couponSection{
  padding: 0;
}
/* Show mobile timer only on mobile devices */
@media (max-width: 768px) {
  .mobileTimerContainer {
    display: block;
  }

  /* Hide desktop timer in right section */
  .rightSection .timer {
    display: none;
  }

  .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .timer__text {
    color: #000;
  }

  .timer__digits {
    color: #FF8E4C;
  }
}

.mobileSummaryContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
}

.productCard {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  width: 400px;
  height: auto;
  padding: 30px;
  border-radius: 28px
}

.productImage {
  width: 100px;
  height: 150px;
  object-fit: contain;
}

.productDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.productTitle {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: normal;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
  font-size: 22px;
}

.quantityControlBtn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid #e2e8f0;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceBreakdown {
  margin-top: 20px;
    width: 400px;
    height: auto;
    padding: 30px;
    border-radius: 28px;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 20px;
}

.priceRow.total {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  font-weight: bold;
}

.priceRow.coupon {
  color: #FF8E4C;
}

.couponSection {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.couponSection input {
  flex: 1;
  padding: 20px 25px;
  border: 1px solid #e2e8f0;
  border-radius: 18px;
  box-shadow: 0px 0px 15px rgba(255, 142, 77, 0.2);
}

.couponSection button {
  padding: 12px 20px;
  white-space: nowrap;
  background: transparent;
  border: none;
  font-weight: 500;
  color: #000;
}
@media (max-width: 768px) {
  .leftSection,
  .verticalLine {
    display: none;
  }

  .mobileOrderSummary {
    display: block;
  }

  .summaryToggle {
    display: flex;
  }

  .mobileSummaryContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin-top: 10px;
  }

  .checkoutContainer {
    flex-direction: column;
    padding: 0;
  }

  .rightSection {
    padding: 20px;
    max-width: 570px;
    order: 2; /* Move the right section below */
  }

  .mobileTimerContainer {
    display: block;
    order: 1; /* Ensure timer appears first */
  }

  .productCard {
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
    box-shadow: none;
    padding: 0;
    width: auto;
    height: auto;

  }


  .row {
    grid-template-columns: 1fr;
  }

  .rowThree {
    grid-template-columns: 1fr;
  }
  .productDetails{
    text-align: left;
    font-size: 16px;
  }
  .productTitle {
    font-size: 14px;
  }
  .otpInputs input{
    width: 50px;
    height: 55px;
  }
  .container{
    align-items:normal;
  }
  .timer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .priceRow{
    font-size: 16px;
  }

  .timer__text {
    color: #000;
  }

  .timer__digits {
    color: #FF8E4C;
  }
  .priceBreakdown{
    padding: 0;
    box-shadow: none;
    width: auto;
    height: auto;
  }
}
@media (max-width: 768px) {
  .summaryToggle {
    padding: 0;
    font-size: 20px;
    color: #FF8E4C;
    font-weight: 500;
  }
  
  .mobileSummaryContent {
    padding: 20px;
  }
  .step {
    color: #CDCDCD;
    position: relative; 
    border-bottom: 3px solid #CDCDCD;
    font-size: 16px;
    width: auto;padding: 0 0 0.3rem 0rem;

  }
  
  .step.active {
    color: #ff6b00;
    border-bottom: 3px solid #ff6b00;
    font-size: 16px;
    width: auto;
  }
  .couponSection button{
    position: relative;
    right: 0;
    padding: 0;
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .otpInputs {
    gap: 5px;  /* Reduce gap between inputs */
  }
  
  .otpInputs input {
    width: 30px;  /* Make inputs smaller */
    height: 35px;
    font-size: 1.2em;  /* Slightly smaller font */
    padding: 5px;
  }
  .contactButton ,.contactButton ,.phoneInput{
    padding: 16px 9px;
  }
}