.journeyContainer {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  gap: 1rem;
  margin-bottom: 2rem;
}

.imageSection {
  flex: none; /* Prevent the image section from growing/shrinking */
  width: 350px;
}

.imageSection img {
  width: 100%;
  height: auto; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Ensure the image covers its container */
  display: block;
  max-height: 100%; /* Limit the height to match the content */
}

.contentSection {
  flex: 1; /* Allow the content section to grow */
  padding: 2rem;
  max-width: 850px;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #333333 !important;
  padding-left: 1rem;
}

.subtitle {
  font-size: 1.87rem;
  font-weight: 700;
  margin: 1.3rem 0;
  color: #000;
}

.timeBlock {
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.time {
  font-weight: 700;
  font-size: 1.6rem;
  color: #333333;
  white-space: nowrap;
}

.description {
  font-size: 1.5rem;
  font-weight: 500;
  color: #6b7280;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .journeyContainer {
    flex-direction: column; /* Stack sections vertically for mobile view */
  }

  .imageSection {
    width: 100%; /* Image section takes full width */
  }

  .imageSection img {
    width: 100%; /* Full-width for image */
    height: 200px; /* Fixed height to give it a rectangular appearance */
    object-fit: cover; /* Ensure the image content fits the rectangle */
  }

  .contentSection {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.3rem;
    padding-left: 0;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .time {
    font-size: 1rem;
  }

  .description {
    font-size: 0.9rem;
  }
  .divider{
    display: none;
  }
}
