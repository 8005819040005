/* ProductLanding.module.css */
.container {
  display: flex;
  flex-direction: column;
  /* gap: 3rem;
  padding: 2rem 5%;  */
  background-color: white;
}
.roseBackground {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.roseFragrance {
  color: #C70000;
  font-size: 20px;
  margin-top: 1rem;
  font-style: italic;
  font-weight: bold;
}
/* Hero Section Styles */
.heroSection {
  text-align: left;
  max-width: 600px;
  padding-left: 80px;
}

.heroSection h1 {
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #000 !important;
}

.heroSection p {
  font-size: 20px;
  color: #666;
  line-height: 1.6;
  max-width: 500px;
}

/* Product Section Styles */
.productSection {
  display: flex;
  justify-content: flex-end;
}

.productContent {
  display: flex;
  align-items: center;
  /* gap: 4rem; */
  padding-right: 90px;
  margin-top: -200px;
}

.productImage {
  width: 340px;
  height: auto;
  position: relative;
  top: 24px;
}

.productInfo {
  max-width: 420px;
  text-align: left;
}

.productInfo h2 {
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.productInfo p {
  font-size: 20px;
  color: #666;
  margin-bottom: 2rem;
}

.orderButton {
  background: #000;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 28px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
}

/* Tradition Section Styles */
.traditionSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  margin-top: -120px;
}

.traditionContent {
  max-width: 480px;
}

.traditionContent h2 {
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.traditionContent p {
  font-size: 20px;
  color: #666;
  line-height: 1.6;
}

.mandalaImage {
  width: 380px;
  height: auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .heroSection,
  .traditionSection {
    padding-left: 40px;
  }

  .productContent {
    padding-right: 40px;
    gap: 2rem;
  }

  .heroSection h1,
  .productInfo h2,
  .traditionContent h2 {
    font-size: 48px;
  }

  .productImage,
  .mandalaImage {
    width: 280px;
  }
}

@media (max-width: 768px) {
  .container {
    gap: 0;
    padding: 0;
  }

  .heroSection,
  .traditionSection {
    padding-left: 20px;
    max-width: 200px;
  }
  .traditionSection{
    max-width: none;
    margin-top: -2rem;
  }

  .productContent {
    /* flex-direction: column-reverse; */
    padding-right: 0px;
    gap: 0rem;
    margin: 0;
    margin-top: -70px;
  }

  .productImage {
    width: 180px !important;
    height: 205px;
    /* top: 0; */
    padding-left: 20px;
  }

  .heroSection h1,
  .productInfo h2,
  .traditionContent h2 {
    font-size: 18px;
    max-width: 140px;
    margin-bottom: 0.6rem;
  }

  .heroSection p,
  .productInfo p,
  .traditionContent p {
    font-size: 10px;
    max-width: 145px;
    margin: 0.5rem 0;
  }

  .orderButton {
    padding: 6px 20px;
    font-size: 8px;
  }

  .mandalaImage {
    width: 157px;
    height: 200px;
  }
}