.container {
    /* min-height: 100vh; */
    background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
    padding: 2rem;
  }
  
  .header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .title {
    color: #DB6922 !important;
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .offeringsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .offeringCard {
   
    border-top: 2px solid #DB6922;
    padding: 0.5rem 2.5rem;
    transition: transform 0.3s ease;

  }
  
  .offeringTitle {
    font-size: 2rem;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 1rem;
    max-width: 400px;
  }
  
  .offeringDescription {
    color: #4a5568;
    line-height: 1.6;
    max-width: 450px;
    font-size: 1.2rem;
    margin-left: calc(48px + 1.5rem); /* icon width + gap */
  }
  
  .iconWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  
  .titleContainer {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .icon {
    width: 48px;
    height: 48px;
    object-fit: contain;
    flex-shrink: 0;
  }
  
  .offeringTitle {
    margin: 0;
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .offeringsGrid {
      grid-template-columns: 1fr;
    }
    
    .title {
      font-size: 2.5rem;
    }
    
    .titleContainer {
      gap: 1rem;
    }
    
    .icon {
      width: 40px;
      height: 40px;
    }

    .offeringDescription {
      margin-left: calc(40px + 1rem); /* smaller icon width + smaller gap */
    }
    .offeringCard{
      padding: 0.8rem 0.5rem 0;
    }
    .offeringTitle{
      font-size: 1.5rem;
    }
    .offeringDescription{
      font-size: 1rem;
    }
    
  }