.container {
    min-height: 100vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    margin-top: 1px;
  }
  .card {
    background-color: #000000;
    width: 100%;
    max-width: 1200px; 
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 0;
  }
  
  /* Profile Sections */
  .profile, .profileNoUser {
    display: flex;
    align-items: center;
    gap: 60px;
    margin: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: white;
    width: 100vw;
    padding: 20px;
  
    box-sizing: border-box;
    position: relative;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0;
  }
  
  .profileImage, .profileImageNoUser {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #D75E21;
  }
  
  .profileInfo h1, .profileInfoNoUser h1 {
    font-size: 50px;
    margin: 0 0 20px 0;
    font-weight: 600;
    color: #000 !important;
  }
  
  .bottleId {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 12px;
  }
  .roseFragrance{
    color: #CC0000 !important;
    font-size: 35px;
    margin: 0;
  }
  
  .bottleId span {
    font-size: 30px;
    font-weight: bold;
    color: #000;
  }
  
  .bottleId .id {
    background-color: #D75E21;
    color: white;
    padding: 5px 70px;
    border-radius: 30px;
    font-weight: 600;
  }
  
  .orderButton {
    background-color: #D75E21;
    color: white;
    padding: 16px 16px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
  }
  
  /* Ledger Button */
  .ledgerButtonContainer {
    margin: 70px 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .ledgerButton {
    background-color: #D75E21;
    color: white;
    padding: 30px 60px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 40px;
    border: none;
    cursor: pointer;
  }
  
  /* Recent Orders Section */
  .recentOrdersSection {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .sectionTitle {
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    margin: 35px 0;
    color: white;
  }
  
  .productSection {
    margin-bottom: 30px;
  }
  
  .productTitle {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 15px;
    color: white;
    display: flex;
    /* align-items: center; */
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .stockInfo {
    font-size: 25px;
    font-weight: normal;
    color: #ccc;
  }
  
  .ledgerTable {
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .tableHeader {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    padding: 25px;
    background-color: white;
    border-bottom: 1px solid #eee;
    text-align: center;
  }
  
  .tableHeader span {
    color: #D75E21;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  
  .tableRow {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    padding: 16px;
    border-bottom: 1px solid #eee;
    color: black;
    font-size: 20px;
    text-align: center;
  }
  
  .tableRow:last-child {
    border-bottom: none;
  }
  
  .seeMoreLink {
    text-align: right;
    margin-top: 5px;
  }
  
  .seeMoreLink a {
    color: white;
    text-decoration: none;
    font-size: 14px;
  }
  
  .seeMoreLink a:hover {
    text-decoration: underline;
  }
  .profileSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    gap: 10px;
  }
  .profileName{
    color:#000;
    font-size: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .profile, .profileNoUser {
      padding: 15px;
      gap: 15px;
    }
  
    .profileImage, .profileImageNoUser {
      width: 60px;
      height: 60px;
    }
  
    .profileInfo h1, .profileInfoNoUser h1 {
      font-size: 20px;
      margin-bottom: 5px;
    }
    .roseFragrance{
      font-size: 16px;
    }
    .bottleId span{
      font-size: 16px;
    }
    .bottleId .id {
      padding: 3px 30px;

    }
  
    .orderButton {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .ledgerButton {
      font-size: 16px;
      padding: 10px 24px;
      border-radius: 6px;
    }
  
    .sectionTitle {
      font-size: 22px;
      margin: 15px 0;
    }
  
    .productTitle {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .stockInfo {
      font-size: 14px;
    }
  
    .tableHeader span {
      font-size: 14px;
    }
  
    .tableRow {
      font-size: 13px;
      padding: 10px;
    }
    .profileSection{
      margin-left: 0;
    }
    .profileName{
      font-size: 8px;
    }
  }
  
  @media (max-width: 480px) {

    .profile, .profileNoUser {
      padding: 12px;
      gap: 10px;
      flex-direction: row;
      /* text-align: center; */
      margin-left: 0;
    }
  
    .profileImage, .profileImageNoUser {
      width: 100px;
      height: 100px;
    }
  
    .profileInfo h1, .profileInfoNoUser h1 {
      margin-bottom: 0.5rem;
      font-size: 18px;
    }
  
    .orderButton {
      font-size: 12px;
      padding: 6px 12px;
    }
  
    .ledgerButton {
      font-size: 14px;
      padding: 8px 20px;
    }
  
    .sectionTitle {
      font-size: 20px;
    }
  
    .productTitle {
      font-size: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  
    .stockInfo {
      font-size: 12px;
    }
  
    .tableHeader, .tableRow {
      grid-template-columns: 0.8fr 1.2fr 1fr;
      padding: 8px;
    }
  
    .tableHeader span {
      font-size: 12px;
    }
  
    .tableRow {
      font-size: 11px;
    }
  
    .seeMoreLink a {
      font-size: 12px;
    }
  }
  
  /* For very small screens */
  @media (max-width: 360px) {
    .tableHeader, .tableRow {
      grid-template-columns: 0.7fr 1.3fr 1fr;
      padding: 6px;
    }
  
    .tableHeader span {
      font-size: 10px;
    }
  
    .tableRow {
      font-size: 10px;
    }
  
    .productTitle {
      font-size: 14px;
    }
  
    .stockInfo {
      font-size: 11px;
    }
  
    .ledgerButton {
      font-size: 12px;
      padding: 6px 16px;
    }
  }
  
  /* Dialog Styles */
  .dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialogContent {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .dialogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }
  
  .dialogHeader h2 {
    margin: 0;
    color: #000;
    font-size: 20px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  .dialogBody {
    padding: 0;
    overflow-y: auto;
    max-height: 60vh;
  }
  
  .dialogTable {
    width: 100%;
  }
  
  .dialogFooter {
    padding: 15px 20px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
  }
  
  /* Pagination Styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .paginationButton {
    padding: 8px 12px;
    border: 1px solid #ddd;
    background-color: white;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    min-width: 40px;
  }
  
  .paginationButton:hover {
    background-color: #f5f5f5;
  }
  
  .paginationButton:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .activePage {
    background-color: #D75E21;
    color: white;
    border-color: #D75E21;
  }
  
  /* Responsive Dialog */
  @media (max-width: 768px) {
    .dialogContent {
      width: 95%;
    }
    
    .dialogHeader h2 {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .dialogContent {
      width: 98%;
      max-height: 95vh;
    }
    
    .dialogHeader {
      padding: 10px 15px;
    }
    
    .dialogHeader h2 {
      font-size: 16px;
    }
    
    .dialogBody {
      max-height: 70vh;
    }
    
    .paginationButton {
      padding: 6px 10px;
      min-width: 30px;
      font-size: 14px;
    }
  }
  
  