/* Main container */
.productContainer {
  background: linear-gradient(135deg, #DB6922, #753812);
}

/* Content container */
.productContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Image container */
.productImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1%;
}

.productImage {
  width: 800px;
  height: 800px;
  object-fit: contain;
}

/* Text container */
.productTextContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
}

/* Gift text */
.giftText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.giftTitle {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
}

.mainText {
  display: flex;
  align-items: center;
}

.dividerLine {
  width: 300px;
  height: 2px;
  background-color: #fff;
  margin-left: -15%;
}

.vrindavanText {
  font-size: 80px;
  color: #fff !important;
  font-weight: bold;
  margin: 0.5rem;
}

/* Video container for small devices */
.videoContainer {
  display: none;
}

.reactPlayer {
  background-color: #000;
}

/* Responsive styles */
@media (max-width: 768px) {
  .productContent {
    flex-direction: column-reverse;
    align-items: center;
  }

  .productImage {
    order: 2;
    width: 100%;
    height: auto;
  }

  .productTextContainer {
    order: 1;
    align-items: center;
    margin-top: 20px;
  }

  .giftText {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }

  .giftTitle {
    font-size: 24px;
    text-align: center;
  }

  .mainText {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .dividerLine {
    width: 0px;
    height: 0px;
    margin: 0px;
  }

  .vrindavanText {
    font-size: 24px;
    text-align: center;
    margin: 0;
  }

  .responsiveImage {
    display: block;
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .productImageContainer {
    margin-left: -7%;
  }

  .videoContainer {
    display: block;
    width: 100vw;
    height: 50vh;
  }

  .reactPlayer {
    width: 100% !important;
    height: 100% !important;
  }
}
