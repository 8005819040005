.heroContainer {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  position: relative;
  width: 80%;
  max-width: 600px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glowEffect {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 98, 0, 0.2) 0%, transparent 70%);
}

.heroImage {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tag {
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  border: 1px solid white;
  border-radius: 50px;
  color: #fff;
  font-size: 1.8rem;
  letter-spacing: 2px;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 15px rgba(255, 98, 0, 0.3);
  margin-bottom: 40px
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
}

@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@media (max-width: 768px) {
  .imageWrapper {
    width: 90%;
  }
  
  .tag {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .content {
    padding: 1.5rem;
  }
}
  
  